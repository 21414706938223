export const __PROD__ = process.env.REACT_APP_ENV === "production";

export const constants = {
  COMPANY_LOGO_PATH: "/handyy-logo.svg",
  COMPANY_LOGO_INVERSE_PATH: "/handyy-inverse.svg",
  ACCESS_TOKEN_STORAGE_KEY: "handyy-access-token",
  REFRESH_TOKEN_STORAGE_KEY: "handyy-refresh-token",
  GUEST_ADDRESS_SESSION_KEY: "guest-session-address",
  OTP_COOLDOWN_SECONDS: 60,
  DOWNLOAD_APPLE: "https://apps.apple.com/app",
  DOWNLOAD_GOOGLE: "https://play.google.com/store/apps",
};

export const appConfig = {};

export const storeListingUrl = {
  playStore: "https://play.google.com/store/apps/details?id=com.handyyCustomerApp",
  appStore: "https://apps.apple.com/us/app/handyy-sg/id6738724884",
};

export const handyymanStoreListingUrl = {
  playStore: "https://play.google.com/store/apps/details?id=com.handyy",
  appStore: "https://apps.apple.com/us/app/handyyman/id6738644570",
};

export const GENERAL_CLEANING_ID = 48;

export const serviceIds = {
  aircon: "1",
  plumbing: "2",
  electrical: "3",
  furniture: "4",
  cleaning: "5",
  movingDisposal: "6",
};

export const airconSubserviceIds = {
  servicing: "1",
  repair: "2",
  installation: "3",
  replacement: "17",
};

export const airconServiceTypeIds = {
  generalCleaning: "1",
  gasTopUp: "2",
  chemicalCleaning: "3",
  condenserCleaning: "4",
  repair: "5",
  installation: "6",
  replacement: "7",
};

export const cleaningSubserviceIds = {
  generalCleaning: "12",
  deepCleaning: "13",
};

export const othersServiceTypeIds = [
  "138",
  "146",
  "139",
  "136",
  "145",
  "137",
  "140",
  "141",
  "142",
  "143",
  "144",
];

//"Others" option for aircon brand
export const OthersAirconBrand = { value: "Others", label: "Others", id: 1000001 };
export const handyyContact = {
  email: "support@handyy.sg",
  phoneNumber: "+6580205618",
  instagram: "https://www.instagram.com/handyysg/",
};
