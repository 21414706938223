export const facebookOAuthConfig = {
  appId: process.env.REACT_APP_FACEBOOK_CLIENT_ID ?? "",
};

export const googleOAuthConfig = {
  url: "https://www.googleapis.com/oauth2/v1/userinfo",
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? "",
};

export const appleOAuthConfig = {
  clientId: process.env.REACT_APP_APPLE_AUTH_CLIENT_ID ?? "",
  redirectURI: process.env.REACT_APP_APPLE_AUTH_REDIRECT_URI ?? "",
  scope: "name email",
  state: "origin:web",
  usePopup: true,
};
