import axios from "axios";
import { constants } from "src/constant";

const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_API_URL });

API.interceptors.request.use((req) => {
  // check if there is any guest session
  const accessToken =
    sessionStorage.getItem(constants.ACCESS_TOKEN_STORAGE_KEY) ||
    localStorage.getItem(constants.ACCESS_TOKEN_STORAGE_KEY);

  if (accessToken) {
    req.headers.Authorization = `Bearer ${accessToken}`;
  }

  return req;
});

API.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    return Promise.reject(err);
  },
);

export default API;
