import { lazy, Suspense } from "react";
import {
  Route,
  Navigate,
  createRoutesFromElements,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { RoutePaths } from "./routePaths";
import AuthRoute from "src/Routers/AuthRoute";
import { CgSpinner } from "react-icons/cg";
import Layout from "./Layout";

import "react-international-phone/style.css";
import "react-phone-number-input/style.css";
import useChangeFavicon from "src/hooks/useChangeFavicon";

const Landing = lazy(() => import("./Landing"));
const Profile = lazy(() => import("./Profile"));
const BrowseHandyman = lazy(() => import("./BrowseHandyman"));

// BOOKING
const BookingList = lazy(() => import("./Booking/BookingList"));
const BookingDetail = lazy(() => import("./Booking/BookingDetail"));
const BookingPayment = lazy(() => import("./Booking/BookingPayment"));
const BookingInvoice = lazy(() => import("./Booking/BookingInvoice"));
const RaiseIssue = lazy(() => import("./RaiseIssue"));
const BookingDetailGuest = lazy(() => import("./Booking/BookingDetailGuest"));

// MESSAGE
const Message = lazy(() => import("./Message"));
const ChatDetailView = lazy(() => import("./Message/ChatDetailView"));

// PARTNER
const Partner = lazy(() => import("./Partner"));

// CONTACT
const Contact = lazy(() => import("./Contact"));

//TERMS AND CONDITIONS
const TermsConditions = lazy(() => import("./TermsConditions"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));

const GoToApp = lazy(() => import("./GoToApp"));

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path={RoutePaths.GoToApp} element={<GoToApp />} />
      <Route path={RoutePaths.TermsConditions} element={<TermsConditions />} />
      <Route path={RoutePaths.PrivacyPolicy} element={<PrivacyPolicy />} />
      <Route path={RoutePaths.Landing} element={<Landing />} />
      <Route path={RoutePaths.Partner} element={<Partner />} />
      <Route path={RoutePaths.Contact} element={<Contact />} />
      <Route path={`${RoutePaths.Profile}/setup`} element={<Profile />} />
      <Route path={RoutePaths.Booking} element={<BookingList />} />
      <Route element={<AuthRoute />}>
        <Route path={RoutePaths.Message} element={<Message />}>
          {/* reference: https://github.com/remix-run/react-router/discussions/11092 */}
          <Route path={`${RoutePaths.Message}/:id`} element={<ChatDetailView />} />
        </Route>
        <Route path={`${RoutePaths.Booking}/:bookingId`} element={<BrowseHandyman />} />
        <Route path={`${RoutePaths.Booking}/:bookingId/detail`} element={<BookingDetail />} />
        <Route path={`${RoutePaths.Booking}/:bookingId/payment`} element={<BookingPayment />} />
        <Route path={`${RoutePaths.Booking}/:bookingId/invoice`} element={<BookingInvoice />} />
      </Route>

      <Route
        path={`${RoutePaths.Booking}/:bookingId/guest/detail`}
        element={<BookingDetailGuest />}
      />

      {/* Dynamic Pages */}
      <Route path={`${RoutePaths.RaiseIssue}/create`} element={<RaiseIssue />} />

      {/* If no page, navigate to landing */}
      <Route path="*" element={<Navigate to={RoutePaths.Landing} />} />
    </Route>,
  ),
);

const App = () => {
  useChangeFavicon();
  return (
    <Suspense
      fallback={
        <div className="flex h-screen w-full items-center justify-center py-48">
          <CgSpinner className="text-primary-400 size-20 animate-spin" />
        </div>
      }
    >
      <RouterProvider router={routes} />
    </Suspense>
  );
};

export default App;
